<template>
  <div class="content">
    <div class="handle-box">
      <el-button type="primary" icon="el-icon-circle-plus-outline" class="mr20" @click="$refs.formDialog.formVisible = true">发布公告</el-button>
    </div>
    <!-- 列表 -->
    <el-table :data="list" @sort-change="changeTableSort">
      <el-table-column label="" width="20"></el-table-column>
      <el-table-column prop="name" label="标题" align="left" width="300"></el-table-column>
      <el-table-column prop="add_time" label="发布时间" align="left">
		<span slot-scope="scope">{{ scope.row.add_time | date('Y-m-d H:i') }}</span>
      </el-table-column>
      <el-table-column prop="status" label="是否启用" width="180" align="center">
        <el-switch slot-scope="scope" v-model="scope.row.status" :width="36" :active-value="1" :inactive-value="0" @change="stateChange(scope.$index, scope.row)"></el-switch>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="edit(scope.row)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" @click.native.prevent="toDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @current-change="pagination" layout="total,prev,pager,next,jumper" :current-page="page" :page-size="pageLimit" :total="totalSize"></el-pagination>
    </div>
    <!-- 新增、编辑 -->
    <notice-manage ref="formDialog" @refreshData="loadList"></notice-manage>
  </div>
</template>

<script>
import noticeManage from './notice-manage'
import http from '@/utils/cloud'

export default {
  components: {
    noticeManage
  },
  data() {
    return {
      list: [],
      page: 1,
      pageLimit: 20,
      project: localStorage.getItem('mall-project')
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    async loadList() {
      const { page, pageLimit } = this
      const res = await http.POST('/jw-admin', {
        module: 'notice',
        operation: 'getList',
        offset: (page - 1) * pageLimit,
        limit: pageLimit,
        project: JSON.parse(this.project)._id
      })

      this.list = res.data
      this.totalSize = res.affectedDocs
      this.loaded = true
    },
    /* 启用 | 禁用 */
    async stateChange(index, item) {
      const response = await http.POST('/jw-admin', {
        module: 'notice',
        operation: 'setStatus',
        id: item._id,
        status: item.status
      })

      if (response.updated !== 1) {
        this.$message.error(response.msg || '操作失败')
        item.status = item.status == 1 ? 0 : 1
      }
    },
    /* 删除 */
    toDelete(index, item) {
      this.$confirm(`是否要删除：${item.name}`, '删除提示', {
        confirmButtonText: '删除',
        type: 'warning'
      })
        .then(async () => {
          const response = await http.POST('/jw-admin', {
            module: 'notice',
            operation: 'remove',
            id: item._id
          })

          if (response.status === 1) {
            this.$message.success('删除成功')
            this.list.splice(index, 1)
			this.loadList();
          } else {
            this.$message.error(response.msg)
          }
        })
        .catch(() => {})
    },
    /* 编辑 */
    edit(item) {
      this.$refs.formDialog.formData = JSON.parse(JSON.stringify(item))
      this.$refs.formDialog.formVisible = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
