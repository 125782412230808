<template>
  <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
    <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
      <el-form-item label="公告标题" prop="name">
        <el-input v-model="formData.name" clearable maxlength="20" placeholder="请输入公告标题" />
      </el-form-item>
	  <el-form-item v-if="formVisible" label="图片" prop="image">
	    <Imgpond v-model="formData.image" :count="1" />
	  </el-form-item>
      <el-form-item label="公告详情" prop="content">
        <Edit></Edit>
        <!-- <MiniMCE v-if="formVisible" style="width: 700px" v-model="formData.content" /> -->
      </el-form-item>
      <el-form-item label="是否启用" prop="status">
        <el-switch class="has-text" style="margin-left: 6px" v-model="formData.status" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用">></el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="padding-left: 140px">
      <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
      <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Edit from '@/components/edit'
import http from '@/utils/cloud'
import imageUpload from '@/components/image-upload/image-upload'

export default {
  components: {
    imageUpload,
    Edit
  },
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      formVisible: false, //表单显示状态
      formData: {}, //表单数据
      rules: {
        name: [{ required: true, message: '请输入公告标题', trigger: 'blur' }],
        content: [{ required: true, message: '请编辑公告详情', trigger: 'change' }],
        status: [{ required: true }]
      }
    }
  },
  computed: {
    formTitle() {
      return this.formData._id ? '修改公告' : '添加公告'
    }
  },
  watch: {
    formData(val) {
    },
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            name: '',
			content:'',
            status: 1
          }
          this.$nextTick(() => {
            if (tinymce.activeEditor) {
              tinymce.activeEditor.setContent('')
            }
          })
        }else{
          this.getDetail()
        }
      } else {
        this.$refs.dataForm.resetFields()
        this.formData = {}
      }
    }
  },
  methods: {
	async getDetail() {
	  const project = localStorage.getItem('mall-project')
	  const response = await http.POST('/jw-admin', {
	    module: 'notice',
	    operation: 'getArticle',
	    id: this.formData._id,
	    project: JSON.parse(this.project)._id
	  })
	  this.formData.content = response.content
	  this.$nextTick(() => {
	    if (tinymce.activeEditor) {
	      tinymce.activeEditor.setContent(response.content)
	    } else {
	      setTimeout(() => {
	        tinymce.activeEditor.setContent(response.content)
	      }, 1000)
	    }
	  })
	},
    //提交
    async submit() {
      this.formData.content = tinymce.activeEditor.getContent()
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }

        const operation = this.formData._id ? 'update' : 'add'
        const response = await http.POST('/jw-admin', {
          module: 'notice',
          operation: operation,
          project: JSON.parse(this.project)._id,
          ...this.formData
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    },
    onImageChanged(files) {
      this.formData.image = files.length > 0 ? files[0] : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
</style>
